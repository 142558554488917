<template>
    <div class="min-h-screen flex relative lg:static surface-ground" v-if="applicationReadIndex === 0">
        <div
            class="surface-section h-screen hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-1 surface-border select-none"
            style="width: 280px;">
            <div class="flex flex-column h-full bg-blue-500">
                <div class="flex align-items-center p-3 pt-5 pb-3 flex-shrink-0" style="height: 82px;">
                    <img src="../assets/img/logo.png" style="height: 43px;"/>
                </div>
                <div class="overflow-y-auto">
                    <ul class="list-none p-3 m-0 mt-3 overflow-hidden">
                        <li
                            v-for="menuItem, index in userMenuItems"
                            v-bind:key="menuItem.label"
                        >
                            <template v-if="menuItem.icon">
                                <router-link
                                    :to="menuItem.path"
                                    class="
                                                no-underline
                                                flex
                                                align-items-center
                                                cursor-pointer
                                                p-3
                                                border-round
                                                text-300
                                                hover:bg-blue-400
                                                hover:text-200
                                                transition-duration-150
                                                transition-colors
                                            "
                                    :class="{'bg-blue-400 text-200': menuItem.path === $route.path}"
                                >
                                    <i class="mr-4" :class="menuItem.icon"></i>
                                    <span>{{ menuItem.label }}</span>
                                </router-link>
                            </template>
                            <div v-else
                                 class="p-2 px-1 flex align-items-center justify-content-between text-blue-200 cursor-pointer"
                                 :class="{'mt-4': index > 0}"
                            >
                                <span class="uppercase">{{ menuItem.label }}</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="mt-auto">
                    <div
                        class="no-underline m-3 mb-0 flex align-items-center cursor-pointer p-3 border-round text-700 transition-duration-150 transition-colors">
                        <div class="block">
                            <span class="text-blue-200 text-sm mb-1 block uppercase">Eingeloggt als</span>
                            <div class="text-200">
                                {{ $store.getters.currentUser.username }}
                            </div>
                        </div>
                    </div>
                    <ul class="list-none m-3 mt-0 pl-0 overflow-hidden">
                        <li class="" v-for="(menuItem, i) in menuItemsUser" :key="i">
                            <router-link
                                :to="menuItem.path"
                                class="
                                                no-underline
                                                flex
                                                align-items-center
                                                cursor-pointer
                                                p-3
                                                border-round
                                                bg-blue-500
                                                hover:bg-blue-400
                                                text-200
                                                transition-duration-150
                                                transition-colors
                                            "
                            >
                                <i class="mr-4"
                                   :class="menuItem.icon"
                                ></i>
                                {{ menuItem.label }}
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="max-h-screen overflow-y-scroll w-full">
            <div
                v-if="$route.meta.headline"
                class="flex align-items-center px-5 surface-0 border-bottom-1 surface-border"
                style="height: 82px;"
            >
                <div style="max-width: 1024px; margin: 0 auto; width: 100%;">
                    <div class="text-4xl uppercase text-900">
                        <template>
                            {{ $route.meta.headline }}
                        </template>
                    </div>
                </div>
            </div>
            <div class="p-5 flex flex-column flex-auto">
                <router-view style="max-width: 1024px; margin: 0 auto; width: 100%;">
                </router-view>
            </div>
        </div>

        <ConfirmDialog></ConfirmDialog>
    </div>
</template>

<script>
    import ConfirmDialog from 'primevue/confirmdialog';

    export default {
        components: {
            ConfirmDialog
        },
        mounted() {
            this.$getFromApi('account', 'me', (entity) => {
                this.$store.commit('setCurrentUser', entity);
                this.applicationReadIndex--;
            });
            this.$http.get('settings').then(response => {
                this.$store.commit('setSettings', response.data);
                this.applicationReadIndex--;
            });
        },
        computed: {
            routeMeta() {
                return this.$route.meta ?? {};
            },
            loadingIndex() {
                return this.$store.getters.getLoadingIndex;
            },
            currentUser() {
                return this.$store.getters.currentUser;
            },
            userMenuItems() {
                return this.menuItems.filter((item) => {
                    return !('userCanSee' in item) || item.userCanSee(this.$store.getters.currentUser)
                });
            }
        },
        data() {
            return {
                applicationReadIndex: 2,
                menuItemsUser: [
                    {
                        label: 'Profil',
                        path: '/profile',
                        icon: 'fi fi-rr-address-book'
                    },
                    {
                        label: 'Logout',
                        path: '/logout',
                        icon: 'fi fi-rr-sign-out-alt'
                    }
                ],
                menuItems: [
                    {
                        label: 'Applikation',
                    },
                    /*{
                        icon: 'fi fi-rr-chart-histogram',
                        label: 'Dashboard',
                        path: '/'
                    },
                    {
                        icon: 'fi fi-rr-incognito',
                        label: 'Research & Matching',
                        path: '/research'
                    },
                     */
                    {
                        icon: 'fi fi-rr-scale',
                        label: 'Anfragen',
                        path: '/requests'
                    },
                    {
                        icon: 'fi fi-rr-pulse',
                        label: 'Dienstleistungsangebote',
                        path: '/offers'
                    },
                    /*{
                        label: 'Verwaltung',
                    },*/
                    {
                        icon: 'fi fi-rr-chart-network',
                        label: 'Dienstleister',
                        path: '/providers'
                    },
                    {
                        icon: 'fi fi-rr-building',
                        label: 'Kunden',
                        path: '/customers'
                    },
                    /*{
                        icon: 'fi fi-rr-money',
                        label: 'Reporting & Abbrechnung',
                        path: '/reporting',
                        userCanSee: function (user) {
                            return Object.keys(user.permissions).indexOf('reporting') >= 0
                                && user.permissions['reporting'] === true;
                        }
                    },*/
                    {
                        label: 'Einstellungen',
                    },
                    {
                        icon: 'fi fi-rr-user',
                        label: 'Benutzer',
                        path: '/users',
                        userCanSee: function (user) {
                            return Object.keys(user.permissions).indexOf('user-real') >= 0
                                && user.permissions['user-real'] === true;
                        }
                    },
                    {
                        icon: 'fi fi-rr-user-add',
                        label: 'Gruppen',
                        path: '/user-groups',
                        userCanSee: function (user) {
                            return Object.keys(user.permissions).indexOf('user-groups') >= 0
                                && user.permissions['user-groups'] === true;
                        }
                    },
                    {
                        icon: 'fi fi-rr-lock',
                        label: 'API & Integrationen',
                        path: '/api-keys',
                        userCanSee: function (user) {
                            return Object.keys(user.permissions).indexOf('user-api-keys') >= 0
                                && user.permissions['user-api-keys'] === true;
                        }
                    },
                    {
                        icon: 'fi fi-rr-database',
                        label: 'Version & Status',
                        path: '/version-and-status'
                    },
                    {
                        icon: 'fi fi-rr-fingerprint',
                        label: 'Token',
                        path: '/token'
                    },
                ],
            }
        }
    }
</script>
